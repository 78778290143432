import React, { Component } from 'react';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import $ from 'jquery';
import { login, signup } from './authActions';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Messages from '../common/msg/messages';
import Input from '../common/form/inputAuth';
import ModalLoading from '../sections/modals/modal-loading';
import Logo from '../assets/images/logo-excelencia.png';
import CONSTS from '../config/consts';
import api from '../api';

import './auth.scss';

class Auth extends Component {
    constructor(props) {
      super(props);
      this.state = { loginMode: true };
    }

    componentDidMount() {
      this.checkHeight();
      var url_string = window.location.href;
      var url = new URL(url_string);

      //se o sistema estiver sendo carregado do app desktop ou app android
      //passamos a variável no login para possíveis checagens
      //quem estiver com a permissão de acesso remoto poderá acessar normalmente
      //se não, não será permitido
      if(url.searchParams.get("app")){
        localStorage.setItem('app',url.searchParams.get("app"));
      }

      window.history.pushState({}, document.title, "/");

      //-----
      // if (process.env.NODE_ENV === 'development') {
        // faz o login automatico em desenvolvimento
        // const loginData = {usuario: "TESTE-CLINICA", senha: "@clinica_excelencia"};
        // const loginData = {usuario: "excelencia_pc", senha: "@clinica_excelencia"};
      //   setTimeout(()=>this.onSubmit(loginData), 3000);
      // }
    }

    componentWillMount(){
      this.checkHeight();
    }

    componentWillUnmount(){
      clearTimeout(this.state.timer);
    }

    checkHeight(){
      const _h = $(window).height();
      $('.login-box').height(_h);
    }

    changeMode() {
      this.setState({ loginMode: !this.state.loginMode });
    }

    onSubmit(values) {
      const timer = setTimeout(()=>{
        this.setState({ModalLoading:false});
      }, 2000);

      this.setState({ModalLoading:true, timer: timer});

      const { login, signup } = this.props;
      this.state.loginMode ? login(values) : signup(values);
    }

    render() {
        const { loginMode } = this.state;
        const { handleSubmit } = this.props;

        return (
            <div className="login-box">
              <ModalLoading className='Modal_Loading' isOpen={this.state['ModalLoading']} />

                <div className='wrapper-login-box'>
                  <div className="login-logo">
                  <img className='brand-login' alt='Logomarca Clínica Excelência' src={Logo} />
                  <div className='ambiente-sistema'>{CONSTS.AMBIENTES[api.AMBIENTE].value}</div>
                  </div>
                  <div className="login-box-body">
                      <p className="login-box-msg"></p>
                      <form onSubmit={handleSubmit(v => this.onSubmit(v))}>
                          <Field component={Input} type="input" name="name"
                              placeholder="Nome" icon='usuario' hide={loginMode} />
                          <Field component={Input} type="text" name="usuario"
                              placeholder="Usuário" icon='user' />
                          <Field component={Input} type="password" name="senha"
                              placeholder="Senha" icon='lock' />
                          <Field component={Input} type="password" name="confirm_password"
                              placeholder="Confirmar Senha" icon='lock' hide={loginMode} />
                          <Row>
                              <Grid cols="">
                                  <button type="submit"
                                      className="btn btn-primary btn-block btn-flat btn-login">
                                      {loginMode ? 'Entrar' : 'Registrar'}
                                  </button>
                              </Grid>
                          </Row>
                      </form>
                      <br />
                      <span>Versão {CONSTS.VERSION}</span>
                      {/*
                        <a onClick={() => this.changeMode()}>
                            {loginMode ? 'Novo usuário? Registrar aqui!' :
                                'Já é cadastrado? Entrar aqui!'}
                        </a>
                      */}
                  </div>
                </div>
                <Messages />
            </div>
        )
    }
}

Auth = reduxForm({ form: 'authForm' })(Auth)
const mapDispatchToProps = dispatch => bindActionCreators({ login, signup }, dispatch)
export default connect(null, mapDispatchToProps)(Auth)
