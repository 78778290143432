const PAGINATE = {"total":0,"current":1,"pages":0,"next":1,"prev":1,"max_results":1};
const TIPO_COMISSAO = [
  {value:1,label:'%'},
  {value:2,label:'$'},
  {value:'',label:'Nenhum'},
];
const SIM_NAO = [
  {value:1,label:'Sim'},
  {value:0,label:'Não'}
];
const PARCELAS = [
  {value:1,label:'1'},
  {value:2,label:'2'},
  {value:3,label:'3'},
  {value:4,label:'4'},
  {value:5,label:'5'},
  {value:6,label:'6'},
  {value:7,label:'7'},
  {value:8,label:'8'},
  {value:9,label:'9'},
  {value:10,label:'10'},
  {value:11,label:'11'},
  {value:12,label:'12'}
];
const METODOS_PAGAMENTO = [
  {value:'10',label:'Link'},
  {value:'11',label:'Pix'},
  {value:'1',label:'Cartão de crédito'},
  {value:'2',label:'Dinheiro', maxParcelas: 1},
  {value:'3',label:'Cartão de débito', maxParcelas: 1},
  {value:'4',label:'Transferência', maxParcelas: 1},
  {value:'5',label:'Picpay'},
  {value:'6',label:'Vale'},
  {value:'7',label:'Recorrente'},
  {value:'8',label:'Funcionário'},
  {value:'9',label:'Voucher/Brinde/Cortesia'},
  {value:'12',label:'Crediário'},
  {value:'13',label:'Boleto'}
];
const TIPOS_CONTAS = [
  {value:'1',label:'Salários'},
  {value:'2',label:'Vale transporte'},
  {value:'3',label:'Internet'},
  {value:'4',label:'Telefones fixo', maxParcelas: 1},
  {value:'5',label:'Telefone celular', maxParcelas: 1},
  {value:'6',label:'Luz / Energia Elétrica', maxParcelas: 1},
  {value:'7',label:'Água'},
  {value:'8',label:'Aluguel, iptu, condom.'},
  {value:'9',label:'Material impresso p/ divulgação'},
  {value:'10',label:'Anúncios'},
  {value:'11',label:'Material de Escritório'},
  {value:'12',label:'Material de limpeza'},
  {value:'13',label:'Deslocamentos/combustível/passagens, estadias'},
  {value:'14',label:'Infra-estrutura'},
  {value:'15',label:'Treinamentos/cursos'},
  {value:'16',label:'Aparelhos em geral'},
  {value:'17',label:'Tranferências'},
  {value:'18',label:'Outros'},
];
const TIPOS_USUARIOS = [
  {value:'1',label:'Gerente'},
  {value:'2',label:'Recepcionista'},
  {value:'3',label:'Financeiro'},
  {value:'4',label:'Supervisor'},
  {value:'6',label:'Consultor de vendas'},
  //{value:'5',label:'Master'},
  {value:'7',label:'Esteticista'}
];
const MODALIDADE_USUARIOS = [
  {value:'1',label:'Biomédicas'},
  {value:'2',label:'Nutricionistas'},
  {value:'3',label:'Esteticistas'},
  // {value:'4',label:'Esteticista VIP'},
  // {value:'5',label:'Freelancer'},
  // {value:'6',label:'Dermatologista'},
  // {value:'7',label:'Enfermeira esteta'},
  // {value:'8',label:'Fisioterapeuta'},
  // {value:'9',label:'Nutróloga'},
];
const PERMISSOES_USUARIOS = [
  { label: "Mensagens", name: "mensagens", cols: "12 3", value: null},
  { label: "Áreas/Regiões do corpo", name: "areas", cols: "12 3", value: null},
  { label: "Patrimônio e aportes", name: "patrimonio" , cols: "12 3", value: null},
  { label: "Serviços", name: "servicos", cols: "12 3", value: null},
  { label: "Unidades", name: "unidades" , cols: "12 3", value: null},
  { label: "Controle de atividades", name: "controle_atividades" , cols: "12 3", value: null},
  { label: "Caixa", name: "caixa" , cols: "12 3", value: null},
  { label: "Relatórios", name: "relatorios" , cols: "12 3", value: null},
  { label: "Fornecedores", name: "fornecedores" , cols: "12 3", value: null},
  { label: "Estoque", name: "estoque" , cols: "12 3", value: null},
  { label: "Financeiro", name: "financeiro" , cols: "12 3", value: null},
  { label: "Perfis de comissionamento", name: "perfis_comissionamento" ,   cols: "12 3", value: null},
  { label: "Usuários", name: "usuarios" , cols: "12 3", value: null},
  { label: "Profissionais", name: "profissionais" , cols: "12 3", value: null},
  { label: "Equipamentos", name: "equipamentos" , cols: "12 3", value: null},
  { label: "Salas", name: "salas", cols: "12 3", value: null},
  { label: "Clientes", name: "clientes" , cols: "12 3", value: null},
  { label: "Planos/Combos/Promoções", name: "planos" , cols: "12 3", value: null},
  { label: "Vendas", name: "vendas" , cols: "12 3", value: null},
  { label: "Agendamentos", name: "agendamentos" ,   cols: "12 3", value: null}
];

const ESTADOS = [
  {value:'AC',label:'Acre'},
  {value:'AL',label:'Alagoas'},
  {value:'AM',label:'Amazonas'},
  {value:'AP',label:'Amapá'},
  {value:'BA',label:'Bahia'},
  {value:'CE',label:'Ceará'},
  {value:'DF',label:'Distrito Federal'},
  {value:'ES',label:'Espírito Santo'},
  {value:'GO',label:'Goiás'},
  {value:'MA',label:'Maranhão'},
  {value:'MG',label:'Minas Gerais'},
  {value:'MS',label:'Mato Grosso do Sul'},
  {value:'MT',label:'Mato Grosso'},
  {value:'PA',label:'Pará'},
  {value:'PB',label:'Paraíba'},
  {value:'PE',label:'Pernambuco'},
  {value:'PI',label:'Piauí'},
  {value:'PR',label:'Paraná'},
  {value:'RJ',label:'Rio de Janeiro'},
  {value:'RN',label:'Rio Grande do Norte'},
  {value:'RO',label:'Rondônia'},
  {value:'RR',label:'Roraima'},
  {value:'RS',label:'Rio Grande do Sul'},
  {value:'SC',label:'Santa Catarina'},
  {value:'SE',label:'Sergipe'},
  {value:'SP',label:'São Paulo'},
  {value:'TO',label:'Tocantins'}
];

const TIPO_PESSOAS = [
  { label: "Física", name: "tipo_pessoa", value: "F",  cols: "12 3"},
  { label: "Jurídica", name: "tipo_pessoa", value: "J",  cols: "12 3"}
];

const TIPO_CAIXA = [
  { label: "Entrada", name: "tipo", value: "1",  cols: "12 3"},
  { label: "Saída", name: "tipo", value: "2",  cols: "12 3"}
];

const MODALIDADES_CAIXA = [
  {value:'12',label:'Link'},
  {value:'13',label:'Pix'},
  {value:'1',label:'Envelope'},
  {value:'6',label:'Vendas'},
  {value:'2',label:'Reembolso'},
  // {value:'3',label:'Dr Ana particular'},
  {value:'4',label:'Despesas diversas'},
  {value:'5',label:'Entrada do financeiro'},
  {value:'7',label:'Venda de produtos'},
  {value:'9',label:'Troca de pacote'},
  {value:'10',label:'Tranferência de pacote'},
  {value:'11',label:'Voucher'},
  {value:'14',label:'Crediário'},

];

const GRAU_PARENTESCO = [
  {value:'1',label:'Pai/mãe'},
  {value:'2',label:'Familiar'},
  {value:'3',label:'Amigo/Conhecido'},
  {value:'4',label:'Esposo/esposa'},
  {value:'5',label:'Relacionamento estável'}
];

const TIPOS_SALAS = [
  { label: "Serviço", value: "1",  cols: "12 3"},
  { label: "Avaliação", value: "2",  cols: "12 3"}
];

const SEXO = [
  {value:'1',label:'Masculino'},
  {value:'2',label:'Feminino'}
];

const TERMOS_CONSCENTIMENTO = [
  {value:'FICHA_CRIOLIPOLISE',label:'Criolipólise'},
  {value:'FICHA_MICROAGULHAMENTO',label:'Microagulhamento'},
  {value:'FICHA_JATO_PLASMA',label:'Jato de Plasma'}
];

const TERMOS_CONSCENTIMENTO_LABEL = {
  ficha_criolipolise:'FICHA_CRIOLIPOLISE',
  ficha_microagulhamento:'FICHA_MICROAGULHAMENTO',
  ficha_jato_plasma:'FICHA_JATO_PLASMA',
};

const OPCOES_CIVIL = [
  {value:'1',label:'Solteiro(a)'},
  {value:'2',label:'Casado(a)'},
  {value:'3',label:'Divorciado(a)'},
  {value:'4',label:'Separado(a)'},
  {value:'5',label:'Viúvo(a)'},
  {value:'6',label:'União estável'},
];

const TIPOS_SANGUES = [
  {value:'A+',label:'A+'},
  {value:'A-',label:'A-'},
  {value:'B+',label:'B+'},
  {value:'B-',label:'B-'},
  {value:'AB+',label:'AB+'},
  {value:'AB-',label:'AB-'},
  {value:'O+',label:'O+'},
  {value:'O-',label:'O-'},
];

const TIPOS_PLANOS = [
  {value:'1',label:'Planos'},
  {value:'2',label:'Combos'},
  {value:'3',label:'Promoções'},
  {value:'4',label:'Outros'}
];

const STATUS_VENDAS = [
  {value:'1',label:'Aprovado'},
  {value:'2',label:'Cancelado'},
  {value:'3',label:'Suspenso'},
  {value:'4',label:'Finalizado'}
];

const VENDA_FINALIZADA = [
  {value:'1',label:'Finalizada'},
  {value:'2',label:'Em aberto'},
];

const TIPOS_VENDA_CORTESIA = [
  { label: "Nenhum", value: ""},
  { label: "Parceria", value: "1"},
  { label: "Cortesia", value: "2"},
  { label: "Voucher",value: "3"},
  { label: "Outros",value: "4"},
];

const STATUS_PAGAMENTO = [
  { label: "Pago", value: 1},
  { label: "Atrasado", value: 2},
  { label: "Aguardando pagamento", value: 3}
];

const STATUS_AGENDAMENTO = [
  {value:'1',label:'Marcado', color:'#1abc9c'},
  {value:'2',label:'Confirmado', color:'#2ecc71'},
  {value:'3',label:'Aguardando', color:'#f1c40f'},
  {value:'4',label:'Atendido', color:'#3498db'},
  {value:'5',label:'Remarcado', color:'#9b59b6'},
  {value:'6',label:'Falhou', color:'#e67e22'},
  {value:'7',label:'Desmarcado', color:'#e74c3c'},
  {value:'8',label:'Em atendimento', color:'#f368e0'},
];

const AVALIACAO_ID = '2';

const TIPOS_AGENDAMENTO = [
  {value:AVALIACAO_ID,label:'Avaliação'},
  {value:'1',label:'Serviço'},
  {value:'3',label:'Retorno'},
];

const TEMPO_ATENDIMENTO = [
  {value:'5',label:'5 min'},
  {value:'10',label:'10 min'},
  {value:'15',label:'15 min'},
  {value:'20',label:'20 min'},
  {value:'25',label:'25 min'},
  {value:'30',label:'30 min'},
  {value:'35',label:'35 min'},
  {value:'40',label:'40 min'},
  {value:'45',label:'45 min'},
  {value:'50',label:'50 min'},
  {value:'55',label:'55 min'},
  {value:'60',label:'1 hora'},
  {value:'70',label:'1 hora 10min'},
  {value:'75',label:'1 hora 15min'},
  {value:'80',label:'1 hora 20min'},
  {value:'85',label:'1 hora 25min'},
  {value:'90',label:'1 hora 30min'},
  {value:'95',label:'1 hora 35min'},
  {value:'100',label:'1 hora 40min'},
  {value:'105',label:'1 hora 45min'},
  {value:'110',label:'1 hora 50min'},
  {value:'120',label:'2 horas'}
];

const WEEKS = {
  monday: {label:'Segunda', value:'monday', id: 1},
  tuesday: {label:'Terça', value:'tuesday', id: 2},
  wednesday: {label:'Quarta', value:'wednesday', id: 3},
  thursday: {label:'Quinta', value:'thursday', id: 4},
  friday: {label:'Sexta', value:'friday', id: 5},
  saturday: {label:'Sábado', value:'saturday', id: 6},
  sunday: {label:'Domingo', value:'sunday', id: 7}
}

const BLOQUEIOS = {
  profissionais: {tipo:'profissionais', id: 1},
  salas: {tipo:'salas', id: 2},
  equipamentos: {tipo:'equipamentos', id: 3}
}

const STATUS_SERVICO_VENDA = [
  {value:'1',label:'Ativo'},
  {value:'2',label:'Suspenso'},
];

const COMO_CONHECEU = [
  {value:'2',label:'Instagram'},
  {value:'7',label:'Facebook'},
  {value:'1',label:'Busca na Internet'},
  {value:'3',label:'Panfleto'},
  {value:'4',label:'Indicação'},
  {value:'5',label:'Televisão'},
  {value:'6',label:'Outros'}
];

const USUARIO_MASTER = 5;//usuário master
const USUARIO_SUPERVISOR = 4;
const USUARIO_GERENTE = 1;

const AMBIENTES = {
  local:{value:'Localhost', type:1},
  treinamento: {value:'Treinamento', type: 2},
  producao: {value:'', type: 3},
  migracao: {value:'', type: 4}
};

const dias = [
  {value: '', label: WEEKS.monday.label, id:WEEKS.monday.value ,cols: '12 3'},
  {value: '', label: WEEKS.tuesday.label, id:WEEKS.tuesday.value ,cols: '12 3'},
  {value: '', label: WEEKS.wednesday.label, id:WEEKS.wednesday.value ,cols: '12 3'},
  {value: '', label: WEEKS.thursday.label, id:WEEKS.thursday.value ,cols: '12 3'},
  {value: '', label: WEEKS.friday.label, id:WEEKS.friday.value ,cols: '12 3'},
  {value: '', label: WEEKS.saturday.label, id:WEEKS.saturday.value ,cols: '12 3'},
  {value: '', label: WEEKS.sunday.label, id:WEEKS.sunday.value ,cols: '12 3'}
];

const VERSION = '1.0.13';

export default {
  WEEK_DAYS: dias,
  AMBIENTES,
  STATUS_SERVICO_VENDA,
  WEEKS,
  API_KEY: 'Allow-Access-Estetica',
  API_KEY_VALUE: 'ZXN0ZXRpY2EtYXBp',
  PAGINATE,
  TIPO_COMISSAO,
  SIM_NAO,
  ESTADOS,
  TIPO_PESSOAS,
  METODOS_PAGAMENTO,
  TIPOS_USUARIOS,
  GRAU_PARENTESCO,
  PERMISSOES_USUARIOS,
  TIPOS_SALAS,
  SEXO,
  OPCOES_CIVIL,
  TIPOS_SANGUES,
  TIPOS_PLANOS,
  STATUS_VENDAS,
  TIPOS_VENDA_CORTESIA,
  PARCELAS,
  STATUS_PAGAMENTO,
  STATUS_AGENDAMENTO,
  TIPOS_AGENDAMENTO,
  MODALIDADE_USUARIOS,
  BLOQUEIOS,
  TEMPO_ATENDIMENTO,
  USUARIO_MASTER,
  USUARIO_SUPERVISOR,
  COMO_CONHECEU,
  AVALIACAO_ID,
  TIPO_CAIXA,
  MODALIDADES_CAIXA,
  TIPOS_CONTAS,
  VENDA_FINALIZADA,
  TERMOS_CONSCENTIMENTO,
  TERMOS_CONSCENTIMENTO_LABEL,
  USUARIO_GERENTE,
  VERSION
}
