import React, { Component } from 'react';
import $ from 'jquery';
import Scrollbar from 'react-scrollbars-custom';
import { connect } from 'react-redux';
import DatePicker, { registerLocale }  from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import pt from 'date-fns/locale/pt';
import { convertToken, getUserAgedamentoOnly } from '../../config/methods';
import MenuItem from './menuItem';
import ButtonAddAjax from '../../common/form/buttonAddAjax';
import MenuTree from './menuTree';
import { withRouter } from "react-router";
import { bindActionCreators } from 'redux';
import LabelAndTimePicker from '../../common/form/labelAndTimerPicker';
import './menu.scss';
import FiltroProfissionais from './filter-menu';
import {filter} from '../../filter/FilterActions';

//-----
import RelatorioProcedimentosAtivos from '../../sections/modals/modal-relatorio-procedimentos-ativos';
import RelatorioAgendamentos from '../../sections/modals/modal-relatorio-agendamentos';
import RelatorioMaisVendidos from '../../sections/modals/modal-relatorio-mais-vendidos';
import RelatorioAniversariantes from '../../sections/modals/modal-relatorio-aniversariantes';
import RelatorioVendasClientes from '../../sections/modals/modal-relatorio-vendas-clientes';
import RelatorioClientesAtivos from '../../sections/modals/modal-relatorio-relatorio-clientes-ativos';
import MensagensAgendamentos from '../../sections/modals/modal-mensagens-agendamentos';
import MensagensPlanos from '../../sections/modals/modal-mensagens-planos';
import RelatorioClassificacaoClientes from '../../sections/modals/modal-classificacao-clientes';
import MensagensAniversariantes from '../../sections/modals/modal-mensagens-aniversariantes';
import RelatorioRetornos from '../../sections/modals/modal-relatorio-retornos';
import RelatorioServicos from '../../sections/modals/modal-relatorio-servicos';
import RelatorioClientesAtivosUnidade from '../../sections/modals/modal-clientes-ativos';
import RelatorioPlanos from '../../sections/modals/modal-relatorio-planos';
import RelatorioPrimeiraUltima from '../../sections/modals/modal-relatorio-primeira-ultima';
import RelatorioVendas from '../../sections/modals/modal-relatorio-vendas';
//-----

registerLocale('pt', pt);

class Menu extends Component{
  constructor(props){
    super(props);

    this.state = {
      date: new Date(),
      searchList: null,
      list:[]
    }

    this.handleInputChange = this.handleInputChange.bind(this);
    this.onClickMenu = this.onClickMenu.bind(this);
    this.searchList = this.searchList.bind(this);
    this.filterAgendamentos = this.filterAgendamentos.bind(this);
  }

  searchList(list){
    this.setState({list},()=>{
      this.filterAgendamentos();
    });
  }

  filterAgendamentos(){
    const { filter } = this.props;

    const data = {
      list:[...this.state.list],
      date: this.state.date, hri: this.state.hri, hrf: this.state.hrf,
      random: Math.random()
    };

    filter(data);
    this.loadAgendamentos();
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
    //this.onClickMenu = this.onClickMenu.bind(this);
  }

  onChange = date => this.setState({ date }, this.filterAgendamentos);

  onClickMenu(){
    this.setState({
      date: new Date(),
      hri: '',
      hrf: ''
    });
  }


  getItemMenu(menu,id){
    const data = convertToken(this.props.user);
    const par = data.par;
    const permissoes = par.permissoes;

    let item;

    //se o usuário for master, força a aparecer todos os itens do menu
    if(Number(par.tipo_usuario) === 5){
      return menu;
    }

    for (let i = 0; i < permissoes.length; i++) {
      const v = permissoes[i];
      if(Number(v.id) === Number(id)){
        item = v.value ? menu : null;
        break;
      }
    }
    return item;
  }

  loadAgendamentos(){
    const { history } = this.props;
    //const state = { ...this.state };
    const data = {
      pathname: '/agendamentos',
      //state
    };

    //se estivermos na seção de agendamentos damos um replace nela, se não, damos um push para ir para ela
    if(history.location.pathname === '/agendamentos' ){
      history.replace(data);
    }else{
      history.push(data);
    }
    this.preventMenuScrolling(false);
  }

  onChangeTime(time, item){
    this.setState({ [item] : time});
  }

//previne ou permite que o menu possa ter scrolling, é chamado quando abre o timer picker
  preventMenuScrolling(check){
    if (check) {
      $('.main-sidebar').addClass('prevent-menu-scrolling');
    }else{
      $('.main-sidebar').removeClass('prevent-menu-scrolling')
    }
  }

  openModal(e, modal){
    this.setState({[modal]: true});
    e.preventDefault();
  }

  closeModal(modal) {
    this.setState({[modal]: false});
  }

  getMenu(){
    const data = convertToken(this.props.user);
    let menu;
    const agendamentoOnly = getUserAgedamentoOnly(this.props.user);

    if(data){
      let agendamentos = this.getItemMenu(<MenuItem onClick={this.onClickMenu} path='/agendamentos' label='Agendamentos' icon='calendar' />, 20);

      let vendas = this.getItemMenu(<MenuItem path='/venda-planos/vendas_planos' label='Venda de Planos' icon='address-book-o' />, 19);
      let planosVendas = this.getItemMenu(<MenuItem path='/vendas-avulsas/vendas_avulsas' label='Vendas avulsas' icon='address-book-o' />, 19);

      let planos = this.getItemMenu(<MenuItem path='/planos' label='Planos/Combos/Promoções' icon='calendar-check-o' />, 18);
      let clientes = this.getItemMenu(<MenuItem path='/clientes' label='Clientes' icon='user-circle-o' />, 17);
      let salas = this.getItemMenu(<MenuItem path='/salas' label='Salas' icon='home' />, 16);
      let equipamentos = this.getItemMenu(<MenuItem path='/equipamentos' label='Equipamentos' icon='gears' />, 15);
      let profissionais = this.getItemMenu(<MenuItem path='/profissionais' label='Profissionais' icon='vcard-o' />, 14);
      let usuarios = this.getItemMenu(<MenuItem path='/usuarios' label='Usuários' icon='group' />, 13);
      let perfis_comissionamento = this.getItemMenu(<MenuItem path='/perfis-comissionamento' label='Perfis de comissionamento' icon='handshake-o' />, 12);
      let estoque = this.getItemMenu(<MenuItem path='/estoque' label='Estoque' icon='shopping-bag' />, 10);
      let fornecedores = this.getItemMenu(<MenuItem path='/fornecedores' label='Fornecedores' icon='address-book-o' />, 9);
      let relatorios = this.getItemMenu(<MenuItem path='/relatorios' label='Relatórios' icon='line-chart' />, 8);
      let controle = this.getItemMenu(<MenuItem path='/controle' label='Controle de atividades' icon='book' />, 6);
      let unidades = this.getItemMenu(<MenuItem path='/unidades' label='Unidades' icon='university' />, 5);
      let servicos = this.getItemMenu(<MenuItem path='/servicos' label='Serviços' icon='star-o' />, 4);
      let patrimonios = this.getItemMenu(<MenuItem path='/patrimonio-aportes' label='Patrimônio e aportes' icon='archive' />, 3);
      let regioes = this.getItemMenu(<MenuItem path='/areas-corpo' label='Áreas/Regiões do corpo' icon='thumbs-up' />, 2);
      //let mensagens = this.getItemMenu(<MenuItem path='/mensagens' label='Mensagens' icon='envelope-o' />, 1);
      let crm = this.getItemMenu(<MenuItem path='/crm' label='CRM' icon='id-card' />, 21);
      let configuracoesGerais = this.getItemMenu(
        <MenuTree label='Configurações gerais' icon='book'>
          <MenuItem path='/configuracoes/permisoes-usuarios' label='Permissões de usuários' icon='users' />
          <MenuItem path='/configuracoes/tipos-profissionais' label='Tipos de profissionais' icon='user-circle-o' />
          <MenuItem path='/configuracoes/contrato' label='Contrato' icon='id-card-o' />
        </MenuTree>, 22);
        //<MenuItem path='/financeiro/vendas' label='Pagamentos (vendas)' icon='dollar' />
      let financeiro = this.getItemMenu(
        <MenuTree label='Financeiro' icon='money'>
          <MenuItem path='/financeiro/pagamentos' label='Fechamento de pagamento' icon='money' />
          <MenuItem path='/financeiro/vendas-realizadas' label='Vendas' icon='dollar' />
          <MenuItem path='/financeiro/parcerias' label='Parcerias/Brindes/Vouchers...' icon='dollar' />
          <MenuItem path='/financeiro/pagamentos-colaboradores' label='Pagamentos funcionários' icon='dollar' />
          <MenuItem path='/financeiro/pagamentos-profissionais' label='Pagamentos profissionais' icon='dollar' />
          <MenuItem path='/financeiro/pagamentos-fornecedores' label='Pagamentos fornecedores' icon='dollar' />
          <MenuItem path='/financeiro/relatorios-contas' label='Relatórios de contas' icon='dollar' />
          <MenuItem path='/financeiro/relatorios-caixa' label='Relatórios de caixa' icon='dollar' />
          <MenuItem path='/financeiro/contas-pagar' label='Contas a pagar' icon='money' />
        </MenuTree>, 11);
      let caixa = this.getItemMenu(<MenuItem path='/caixa' label='Caixa' icon='calculator' />, 7);

      const h = $(window).height() - 50;
      const w = $('.main-sidebar').width();

      menu = (
        <div>

          <RelatorioRetornos
            className='Modal_CRM'
            isOpen={this.state['RelatorioRetornos']}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={()=>this.closeModal('RelatorioRetornos')}
          />
          <MensagensAgendamentos
            className='Modal_CRM Modal_Agendamento'
            isOpen={this.state['MensagensAgendamentos']}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={()=>this.closeModal('MensagensAgendamentos')}
          />
          <MensagensPlanos
            className='Modal_CRM'
            isOpen={this.state['MensagensPlanos']}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={()=>this.closeModal('MensagensPlanos')}
          />
          <MensagensAniversariantes
            className='Modal_CRM'
            isOpen={this.state['MensagensAniversariantes']}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={()=>this.closeModal('MensagensAniversariantes')}
          />
          <RelatorioAgendamentos
            className='Modal_CRM'
            isOpen={this.state['RelatorioAgendamentos']}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={()=>this.closeModal('RelatorioAgendamentos')}
          />
          <RelatorioPrimeiraUltima
            className='Modal_CRM'
            isOpen={this.state['RelatorioPrimeiraUltima']}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={()=>this.closeModal('RelatorioPrimeiraUltima')}
          />
          
          <RelatorioPlanos
            className='Modal_CRM'
            isOpen={this.state['RelatorioPlanos']}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={()=>this.closeModal('RelatorioPlanos')}
          />
          <RelatorioServicos
            className='Modal_CRM'
            isOpen={this.state['RelatorioServicos']}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={()=>this.closeModal('RelatorioServicos')}
          />
          <RelatorioProcedimentosAtivos
            className='Modal_CRM'
            isOpen={this.state['RelatorioProcedimentosAtivos']}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={()=>this.closeModal('RelatorioProcedimentosAtivos')}
          />


          <RelatorioClientesAtivosUnidade
            className='Modal_CRM'
            isOpen={this.state['RelatorioClientesAtivosUnidade']}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={()=>this.closeModal('RelatorioClientesAtivosUnidade')}
          />
          <RelatorioMaisVendidos
            className='Modal_CRM'
            isOpen={this.state['RelatorioMaisVendidos']}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={()=>this.closeModal('RelatorioMaisVendidos')}
          />
          <RelatorioVendas
            className='Modal_CRM'
            isOpen={this.state['RelatorioVendas']}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={()=>this.closeModal('RelatorioVendas')}
          />
          <RelatorioAniversariantes
            className='Modal_CRM'
            isOpen={this.state['RelatorioAniversariantes']}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={()=>this.closeModal('RelatorioAniversariantes')}
          />
          <RelatorioVendasClientes
            className='Modal_CRM'
            isOpen={this.state['RelatorioVendasClientes']}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={()=>this.closeModal('RelatorioVendasClientes')}
          />
          <RelatorioClientesAtivos
            className='Modal_CRM'
            isOpen={this.state['RelatorioClientesAtivos']}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={()=>this.closeModal('RelatorioClientesAtivos')}
          />
          <RelatorioClassificacaoClientes
            className='Modal_CRM'
            isOpen={this.state['RelatorioClassificacaoClientes']}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={()=>this.closeModal('RelatorioClassificacaoClientes')}
          />
          
          <Scrollbar noScrollX style={{ width: w, height: h }}>
            <ul className='sidebar-menu'>
            {
              agendamentoOnly ? null :
              <li>
                <div className='box-date-menu'>
                  <div className='box-date-menu__wrapper'>
                    <DatePicker
                      inline
                      selected={this.state.date}
                      onChange={this.onChange}
                      locale="pt"
                    />
                    <div className='clearfix timer-menu-select'>
                      <LabelAndTimePicker
                        onFocus={() => this.preventMenuScrolling(true)}
                        onBlur={() => this.preventMenuScrolling(false)}
                        name='hri'
                        handleChange={time => this.onChangeTime(time, 'hri')}
                        label='De'
                        cols='12 4'
                        placeholder='Digite aqui'
                        startDate={this.state.hri} />
                      <LabelAndTimePicker
                        onFocus={() => $('.main-sidebar').addClass('prevent-menu-scrolling')}
                        onBlur={() => $('.main-sidebar').removeClass('prevent-menu-scrolling')}
                        name='hrf'
                        handleChange={time => this.onChangeTime(time, 'hrf')}
                        label='Até'
                        cols='12 4'
                        placeholder='Digite aqui'
                        startDate={this.state.hrf} />
                    <ButtonAddAjax cols="2" icon="times-circle-o" onClick={()=>this.setState({hrf:'', hri: ''})} />
                    <ButtonAddAjax cols="2" icon="search" onClick={()=>this.filterAgendamentos()} />
                    </div>
                  </div>
                </div>
                <div id="container-filtro">
                  <FiltroProfissionais searchList={this.searchList} />

                </div>
              </li>
            }
              {
                agendamentoOnly ? null :
                <MenuItem path='/' label='Dashboard' icon='dashboard' />
              }
              {agendamentos}
              {
                agendamentoOnly ? null :
                <MenuTree label='Relatórios' icon='bar-chart'>
                  <MenuItem path='/reports/agendamentos' label='Agendamentos' icon='calendar' onClick={(e)=>this.openModal(e,"RelatorioAgendamentos")} />
                  <MenuItem path='/reports/primeira-ultima-sessao' label='Primeira/Última sessão' icon='calendar' onClick={(e)=>this.openModal(e,"RelatorioPrimeiraUltima")} />
                  <MenuItem path='/reports/procedimentos-mais-vendidos/' label='Procedimentos mais vendidos' icon='thumbs-o-up' onClick={(e)=>this.openModal(e,"RelatorioMaisVendidos")} />
                  <MenuItem path='/reports/procedimentos-ativos/' label='Procedimentos ativos' icon='star-o' onClick={(e)=>this.openModal(e,"RelatorioProcedimentosAtivos")} />
                  <MenuItem path='/reports/aniversariantes/' label='Aniversariantes' icon='users' onClick={(e)=>this.openModal(e,"RelatorioAniversariantes")} />
                  <MenuItem path='/reports/vendas-clientes/' label='Vendas por cliente' icon='users' onClick={(e)=>this.openModal(e,"RelatorioVendasClientes")} />
                  <MenuItem path='/reports/vendas-clientes-ativos/' label='Clientes inativos' icon='users' onClick={(e)=>this.openModal(e,"RelatorioClientesAtivos")} />
                  <MenuItem path='/reports/clientes-ativos/' label='Clientes ativos' icon='users' onClick={(e)=>this.openModal(e,"RelatorioClientesAtivosUnidade")} />
                  <MenuItem path='/reports/clientes-classificacao/' label='Clientes que mais compraram' icon='star' onClick={(e)=>this.openModal(e,"RelatorioClassificacaoClientes")} />
                  <MenuItem path='/reports/retorno/' label='Retorno' icon='refresh' onClick={(e)=>this.openModal(e,"RelatorioRetornos")} />
                  {/* <MenuItem path='/reports/vendas-servico/' label='Vendas por serviço' icon='money' onClick={(e)=>this.openModal(e,"RelatorioServicos")} /> */}
                  {/* <MenuItem path='/reports/vendas-planos/' label='Vendas por planos' icon='money' onClick={(e)=>this.openModal(e,"RelatorioPlanos")} /> */}
                  <MenuItem path='/reports/vendas/' label='Vendas gerais' icon='money' onClick={(e)=>this.openModal(e,"RelatorioVendas")} />
                </MenuTree>
              }

              {
                agendamentoOnly ? null :
                <MenuTree label='Mensagens' icon='envelope-open-o'>
                  <MenuItem path='/mensagens/agendamentos' label='Agendamentos' icon='calendar' onClick={(e)=>this.openModal(e,"MensagensAgendamentos")} />
                  <MenuItem path='/mensagens/planos/' label='Planos' icon='calendar-check-o' onClick={(e)=>this.openModal(e,"MensagensPlanos")} />
                  <MenuItem path='/mensagens/aniversariantes/' label='Aniversariantes' icon='users' onClick={(e)=>this.openModal(e,"MensagensAniversariantes")} />
                </MenuTree>
              }

              {crm}
              {planosVendas}
              {vendas}
              {financeiro}
              {planos}
              {clientes}
              {configuracoesGerais}
              {salas}
              {equipamentos}
              {profissionais}
              {usuarios}

              {perfis_comissionamento}

              {estoque}
              {fornecedores}
              {caixa}
              {controle}
              {unidades}
              {servicos}
              {/*<MenuItem path='/configuracoes' label='Configurações gerais' icon='bookmark-o' />*/}
              {patrimonios}
              {regioes}
              {/*mensagens*/}

            </ul>
          </Scrollbar>
        </div>

      );
    }

    return(
      menu
    );
  }

  render(){

    //havia um erro no slimscroll devido a adição do menu depois da chamada do admin lte
    //por isso damos um settimout e chamado os métodos com um pequeno delay
    setTimeout(function(){
      //$.AdminLTE.layout.fixSidebar();
      $.AdminLTE.layout.fix();
      $('.sidebar').removeAttr('style');
    },1000);

    return(
      this.getMenu()
    );
  }
};

const mapStateToProps = store => ({ user: store.auth.user, filter_data: store.filter.value });
const mapDispatchToProps = dispatch => bindActionCreators({ filter }, dispatch);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));

//
// const mapStateToProps = store => ({
//   user: store.auth.user,
//   filter: store.filter.value
// });
//
// export default withRouter(connect(mapStateToProps)(Menu));
