import React, { Component } from 'react';
import ReactModal from 'react-modal';

import TitleModal from '../../common/form/titleModal';
import ModalClienteAjax from '../modals/modal-clientes-ajax';
import ModalAgendamentosAjax from '../modals/modal-agendamentos-ajax';
import Tabs from '../../common/tab/tabs';
import TabsHeader from '../../common/tab/tabsHeader';
import TabsContent from '../../common/tab/tabsContent';
import TabHeader from '../../common/tab/tabHeader';
import TabContent from '../../common/tab/tabContent';
import { showTabs } from '../../common/tab/tabActions';
import ContentAgendamento from '../agendamentos/content-agendamento';
import HistoricoAgendamento from '../agendamentos/historico-agendamento';
import If from '../../common/operator/if';
import { convertToken } from '../../config/methods';
import CONSTS from '../../config/consts';

import './modal-agendamento.scss';

ReactModal.setAppElement('#root');

const INITIAL_STATE_TABS = { selected: '', visible: {} };

/*
  ** Para agendamentos avulsos ao mudar o status para atendido,
  ** é obrigatório o pagamento, assim, abrimos um overlay com os dados do financeiro
*/

export default class Modal extends Component{
  constructor(props){
    super(props);
    this.state = {
      loaded: false,
      date: null,
      tabs: INITIAL_STATE_TABS,
      clienteAjax: null
    }
    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.changeTab = this.changeTab.bind(this);
    this.onAfterOpen = this.onAfterOpen.bind(this);
  }

  componentWillMount() {
    showTabs({ tabId: 'tabGeralAgendamento', tabs: this.state.tabs, component: this });
  }

  onAfterOpen(){
    this.setState({
      dados: this.props.dados
    });
  }

  onChange = date => this.setState({ date });

  openModal(modal) {
    this.setState({[modal]: true});
  }

  afterOpenModal() {

  }

  closeModal(modal) {
    this.setState({[modal]: false});
  }

  changeTab(tabId='tabGeralAgendamento'){
    showTabs({tabId:tabId, tabs: this.state.tabs, component: this});
  }

  renderContent(){
    const tabs = this.state.tabs;

    const data = convertToken(this.props.token);
    const par = data.par;
    const tipoUsuario = par.tipo_usuario;

    return(
      <Tabs>
          <TabsHeader>
              <TabHeader
                label='Dados gerais'
                icon='bars'
                target='tabGeralAgendamento'
                selectTab={this.changeTab}
                tabs={tabs}
                component={this}
              />
              <If test={(String(tipoUsuario) === String(CONSTS.USUARIO_MASTER)) || (String(tipoUsuario) === String(CONSTS.USUARIO_SUPERVISOR) || (String(tipoUsuario) === String(CONSTS.USUARIO_GERENTE)))}>
                <TabHeader
                  label='Histórico'
                  icon='plus'
                  target='tabHistoricoAgendamento'
                  selectTab={this.changeTab}
                  tabs={tabs}
                  component={this}
                />
              </If>
          </TabsHeader>
          <TabsContent>
            <TabContent id='tabGeralAgendamento' tabs={tabs}>
              <ContentAgendamento
                clienteAjax={this.state.clienteAjax}
                onLoad={this.onLoad}
                onCreate={this.props.onCreate}
                openModal={this.openModal}
                token={this.props.token}
                dados={this.props.dados}
                onRequestClose={this.props.onRequestClose}
                tipo={this.props.tipo}
                />
            </TabContent>
            <TabContent id='tabHistoricoAgendamento' tabs={tabs}>
              <If test={tabs.selected === 'tabHistoricoAgendamento'}>
                <HistoricoAgendamento dados={this.props.dados} />
              </If>
            </TabContent>
          </TabsContent>
      </Tabs>
    );
  }

  render(){
    const customStyles = {
      content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
      },
      overlay: {
        backgroundColor: 'rgba(0,0,0,.8)'
      },
    };
    return(
      <ReactModal
        portalClassName={`${this.props.className || ''} ReactModalPortal`}
        isOpen={this.props.isOpen}
        onAfterOpen={this.onAfterOpen}
        onRequestClose={this.props.onRequestClose}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
      >
        <ModalClienteAjax
          onSuccess={(data)=>this.setState({clienteAjax:data})}
          className='Modal_ClienteAjax'
          isOpen={this.state['modalClienteAjax']}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={()=>this.closeModal('modalClienteAjax')}
        />
        <ModalAgendamentosAjax
          className='Modal_AgendamentosAjax'
          isOpen={this.state['modalAgendamentosAjax']}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={()=>this.closeModal('modalAgendamentosAjax')}
        />

        <div className='box-modal-visualizacao'>
          <button className='bt-close-modal' onClick={this.props.onRequestClose}><i className='fa fa-times'></i></button>
          <div className='box-modal-visualizacao__wrapper'>
            <TitleModal title="Agendamento" />

            {
              this.renderContent()
            }
          </div>
        </div>
      </ReactModal>
    )
  }
}
